footer#footer {
  position: relative;
  background-color: $bg-color-footer;
  background-image: url('../images/bg-footer.svg');
  background-repeat: no-repeat;
  background-position: 50% 5rem;
  background-size: 745px;
  min-height: calc(665px + 15rem);
  padding-top: 16rem;
  padding-bottom: 10rem;
  @include media-breakpoint-down(md) {
    background-size: 700px;
  }
  @include media-breakpoint-down($mobile-breakpoint) {
    background-image: url('../images/bg-footer-sm.svg');
    background-size: 241px 556px;
    background-position: 50% 2rem;
    padding-top: 9rem;
    padding-bottom: 4rem;
    min-height: auto;
  }

  .scroll-top {
    $scale: 0.8;
    display: block;
    width: $width-scroll-top * $scale;
    height: $width-scroll-top * $scale;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $bg-color-scroll-top;
    background-image: url('../images/bg-scroll-top.svg');
    background-repeat: no-repeat;
    background-size: 28px * $scale;
    background-position: 50%;
    border-top-right-radius: $width-scroll-top / 2;
    border-top-left-radius: $width-scroll-top / 2;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.4);
    transition: 0.15s;

    &:hover {
      @include media-breakpoint-up(md) {
        background-color: darken($blue, 5%);
        width: $width-scroll-top;
        height: $width-scroll-top;
        background-size: 28px;
      }
    }
    @include media-breakpoint-down($mobile-breakpoint) {
      $coef: 0.7;
      width: $width-scroll-top * $coef;
      height: $width-scroll-top * $coef;
      border-top-right-radius: ($width-scroll-top / 2) * $coef;
      border-top-left-radius: ($width-scroll-top / 2) * $coef;
      background-size: 28px * $coef;
    }
  }

  [class*="col-"][class*="-6"] + [class*="col-"][class*="-6"] {
    @include media-breakpoint-down($mobile-breakpoint) {
      margin-top: 2rem;
    }
  }

  .block-menu {
    & + .block-menu {
      margin-top: 1.5rem;
    }

    h3 {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 400;
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.5rem;
    }

    ul.menu {
      padding: 0;
      margin: 0;
      font-size: 0.875rem;

      li {
        list-style: none;
        margin: 0;
        padding: 0;

        a {
          color: inherit;
        }
      }
    }
  }
}
