body.front {
  .slider {
    margin-top: -2rem;
    margin-bottom: 2rem;

    ul {
      padding: 0;
      margin: 0;

      li {
        position: relative;
        color: #fff;

        .slide-image {
          width: 100%;
          height: auto;
        }

        .container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
