body.page-training-catalogue {
  h1.title {
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 1.5rem;
    color: #0064bf;
  }

  .view-opigno-course-catalgue {
    .views-row {
      margin-bottom: $gutter;

      &:first-child {
        @include media-breakpoint-up(md) {
          flex: 1 0 100% !important;
          max-width: 100% !important;
          padding: 0 !important;
        }

        > * {
          @include media-breakpoint-up(md) {
            max-width: 25%;
            padding-left: $gutter / 2;
            padding-right: $gutter / 2;
          }
        }
      }
    }

    .views-field-opigno-course-image {
      margin-bottom: 0.5rem;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
