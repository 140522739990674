.view-courses,
.view-table-content {
  .views-row {
    margin-bottom: $gutter;

    &:first-child {
      @include media-breakpoint-up(md) {
        flex: 1 0 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
      }

      > * {
        @include media-breakpoint-up(md) {
          max-width: 25%;
          padding-left: $gutter / 2;
          padding-right: $gutter / 2;
        }
      }
    }
  }

  .views-field-opigno-course-image {
    margin-bottom: 0.5rem;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
