@include media-breakpoint-down(md) {
  div#admin-menu {
    display: none;
  }

  html body.admin-menu {
    margin-top: 0 !important;
  }
}

html {
  display: flex;
  height: 100%;

  body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;

    div#main {
      flex: 1 0 auto;
    }

    footer#footer {}
  }
}

.ng-cloak,
.x-ng-cloak,
[data-ng-cloak],
[ng-cloak],
[ng\:cloak],
[x-ng-cloak] {
  display: none !important;
}

h6.ref-title {
  margin: $headings-margin-bottom 0;
}

.copyright {
  font-size: 0.9em;
  margin: 1rem 0 2rem;
}

article.node {
  text-align: justify;
}

table {
  td,
  th {
    border: 0;
  }

  th {
    background-color: rgb(0, 153, 255);
    color: #fff;
  }

  td {
    background-color: rgb(238, 238, 238);
  }

  thead th {
    text-align: center;
    border: 0;
  }
}
