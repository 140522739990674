div#quiz_progress {
  display: none;
}

.opigno-group-progress-course {
  > a {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.3;
  }

  > div {
    margin-top: 0.5rem;

    > .item-list {
      > ul {
        list-style: none;
        padding-left: 20px;

        > li {
          margin-left: 0;

          > a {
            font-size: 18px;

            &.active {
              color: $link-color;

              &:hover {
                color: $link-hover-color;
              }
            }

            &:not(.active-trail).active {
              color: $blue;
              position: relative;

              &::before {
                $size: 10px;
                content: '';
                position: absolute;
                width: $size;
                height: $size;
                border-radius: 100%;
                background-color: $blue;
                left: -1.25rem;
                top: 0.5rem;
              }
            }

            &:not(.active-trail) + .item-list {
              display: none;
            }
          }

          > .item-list {
            > ul {
              list-style: none;

              > li {
                > a {
                  color: #000;

                  &.active {
                    position: relative;

                    &::before {
                      $size: 8px;
                      content: '';
                      position: absolute;
                      width: $size;
                      height: $size;
                      background-color: #0064bf;
                      left: -1.25rem;
                      top: 0.25rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
