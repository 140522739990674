@font-face {
  font-family: 'miriam_librebold';
  src: url('../fonts/miriamlibre-bold-webfont.woff2') format('woff2'), url('../fonts/miriamlibre-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'miriam_libreregular';
  src: url('../fonts/miriamlibre-regular-webfont.woff2') format('woff2'), url('../fonts/miriamlibre-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
