header#header {
  background-color: $bg-color-header;
  background-image: url('../images/bg-header.svg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  min-height: $height-header;
  text-align: center;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  transition: min-height 0.3s;

  @include media-breakpoint-down($mobile-breakpoint) {
    position: relative;
  }

  &.smaller {
    @include media-breakpoint-up(lg) {
      min-height: $height-header-smaller;
    }
  }
  @include media-breakpoint-down($mobile-breakpoint) {
    min-height: $height-header-sm!important;
  }

  body.admin-menu & {
    top: 29px;
    @include media-breakpoint-down(md) {
      top: 0;
    }
  }

  #logo {
    color: #fff;
    font-size: 42px;
    font-family: 'miriam_librebold';
    text-decoration: none;
    display: inline-block;
    margin-top: 30px;
    transition: 0.3s;
    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
    @include media-breakpoint-down($mobile-breakpoint) {
      margin-top: 1rem;
      font-size: 19px;
      max-width: 245px;
    }

    img {
      margin-right: 0.5rem;
      transition: top 0.3s;
      @include media-breakpoint-down($mobile-breakpoint) {
        width: 49px;
        height: 70px;
      }
    }

    span {
      display: inline-block;
      height: 88px;
      vertical-align: top;
      line-height: 88px;
      transition: top 0.3s;
      @include media-breakpoint-down(md) {
        width: 160px;
        line-height: normal;
        height: auto;
        text-align: left;
      }
      @include media-breakpoint-down($mobile-breakpoint) {
        width: 130px;
      }
    }
  }

  #search-region {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $darkblue;
    height: $height-language;
    transition: 0.3s;

    form {
      margin: 10px 0;
      position: relative;

      .container-inline {
        padding-top: 0;

        div,
        label {
          display: block;
          margin: 0;
        }
      }

      .form-text {
        padding: 0 calc(70px + 1rem) 0 1rem;
        margin: 10px auto;
        width: 100%;
        height: 51px;
        border: 0;
        border-radius: 0;
        transition: 0.3s;
      }

      .form-actions {
        position: absolute;
        right: 0;
        top: 0;

        .form-submit {
          height: 51px;
          background-color: transparent;
          background-image: url('../images/bg-search-btn.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 27px;
          border: 0;
          font-size: 0;
          width: 70px;
          cursor: pointer;
          transition: 0.3s;
        }
      }
    }
  }

  #language-region {
    position: absolute;
    top: calc((#{$height-header} - #{$height-language}) / 2);
    transform: translateY(-50%);
    right: 60px;
    transition: top 0.3s;
    @include media-breakpoint-down($mobile-breakpoint) {
      display: none;
    }

    ul.language-switcher-locale-url {
      padding: 0;
      margin: 0;
      list-style: none;
      text-transform: uppercase;
      font-family: 'miriam_libreregular';
      font-size: 20px;

      li {
        display: inline-block;

        & + li {
          margin-left: 0.5rem;
        }

        a {
          text-decoration: none;
          color: #3d8ac4;

          &.active {
            color: #fff;
          }
        }
      }
    }
  }

  &.smaller {
    @include media-breakpoint-up(lg) {
      #language-region {
        top: calc((#{$height-header-smaller} - #{$height-language} + 10px) / 2);
      }

      #logo {
        margin-top: 16px;
        font-size: 36px;

        img {
          height: 60px;
        }

        span {
          height: 60px;
          line-height: 65px;
        }
      }

      #search-region {
        height: calc(#{$height-language} - 10px);

        form {
          .form-text {
            height: 41px;
          }

          .form-submit {
            height: calc(51px - 10px);
          }
        }
      }
    }
  }
}
