body.tableContentOpened {
  overflow: hidden;
}

button.table-content {
  $scale: 0.8;
  width: $width-table-content * $scale;
  height: $width-table-content * $scale;
  background-color: $blue;
  background-image: url('../images/bg-table-content.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 35px * $scale;
  padding: 0;
  border-radius: ($width-table-content / 2) 0 0 ($width-table-content / 2);
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 2px 0 10px 2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  z-index: 10;
  transition: 0.15s;

  &:hover {
    @include media-breakpoint-up(md) {
      background-color: darken($blue, 5%);
      width: $width-table-content;
      height: $width-table-content;
      background-size: 35px;
    }
  }

  body.page-courses & {
    display: none;
  }
  @include media-breakpoint-down($mobile-breakpoint) {
    position: absolute;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    width: 42px;
    height: 42px;
    top: 0.9rem;
    right: 0.9rem;
    transform: none;
    background-size: 32px;
    padding: 5px;
    border: 0;
  }
}

.table-content-wrapper {
  position: fixed;
  top: $height-header;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - #{$height-header});
  background-color: #fff;
  z-index: 11;
  color: $blue;
  overflow: auto;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 2rem;

  body.smaller-header & {
    top: $height-header-smaller;
    height: calc(100vh - #{$height-header-smaller});
  }

  body.admin-menu & {
    @include media-breakpoint-up(lg) {
      height: calc(100vh - #{$height-header} - #{$height-admin-menu});
      top: calc(#{$height-header} + 29px);
    }
    @include media-breakpoint-down(md) {
      top: calc(#{$height-header});
    }
    @include media-breakpoint-down(sm) {
      top: calc(#{$height-header-sm});
    }
  }

  body.admin-menu.smaller-header & {
    top: calc(#{$height-header-smaller} + #{$height-admin-menu});
    @include media-breakpoint-up(md) {
      height: calc(100vh - #{$height-header-smaller} - #{$height-admin-menu});
    }
    @include media-breakpoint-down(md) {
      top: $height-header-smaller;
      height: calc(100vh - #{$height-header-smaller});
    }
  }
  @include media-breakpoint-down(sm) {
    top: $height-header-sm!important;
    height: calc(100vh - #{$height-header-sm});
  }

  .table-content-close {
    width: 35px;
    height: 35px;
    background-image: url('../images/bg-table-content-close.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    background-color: transparent;
    position: absolute;
    right: 1.5rem;
    cursor: pointer;
  }
}

.table-content-wrapper h3.title,
body.page-courses h1.title {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 1.5rem;
  color: $blue;
}
