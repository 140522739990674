div#main {
  padding-top: 2rem;
  padding-bottom: 4rem;
  margin-top: $height-header;
  @include media-breakpoint-down($mobile-breakpoint) {
    margin-top: 0;
  }
}

.block-pac-revisions,
article + .block {
  margin-top: 3rem;
}

.folder-wrapper {
  h4 {
    display: none;
  }
}

.table-mobile-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

table.sticky-header {
  @include media-breakpoint-down($mobile-breakpoint) {
    display: none!important;
  }
}

body.role-anonymous-user {
  #folder-content-container {
    tbody tr td:last-child,
    thead tr th#table-th-ops {
      display: none;
    }
  }
}

.video-js,
iframe {
  max-width: 100%;
}

#content-area {
  img {
    max-width: 100%;
    height: auto!important;
  }
}
