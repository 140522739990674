body.node-type-course {
  ul.action-links {
    margin-top: 4rem;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    font-size: 20px;

    li {
      text-align: right;

      a {
        text-decoration: none;

        &::after {
          content: '';
          display: inline-block;
          margin-left: 0.5rem;
          width: 11px;
          height: 17px;
          background-image: url('../images/bg-next.svg');
          background-repeat: no-repeat;
          background-position: center;
          vertical-align: baseline;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}
