// Globlal
$mobile-breakpoint: sm;
$height-admin-menu: 29px;

// Colors
$blue: #0064bf;
$darkblue: #13467f;

// bootstrap overrides
$enable-flex: true;
$font-family-base: Helvetica, Arial, sans-serif;
$body-color: #4d4d4d;
$btn-focus-box-shadow: none;
$form-text-margin-top: 0;
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$body-color: #000;
$gutter: 30px;
$headings-margin-bottom: 1em;

// header
$bg-color-header: $blue;
$height-header: 223px;
$height-header-smaller: 150px;
$height-header-sm: 177px;
$height-language: 70px;

// footer
$bg-color-footer: #f2f2f2;
$bg-color-scroll-top: $blue;
$width-scroll-top: 70px;

// table content
$width-table-content: 70px;
