body.sidebarOpened {
  overflow: hidden;
}

.sidebar-toggler-icon {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../images/bg-toggler-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  position: absolute;
  top: calc((#{$height-header} - #{$height-language}) / 2);
  transform: translateY(-50%);
  left: 50px;
  padding: 5px;
  box-sizing: content-box;
  cursor: pointer;
  transition: none;
  transition: top 0.3s;
  @include media-breakpoint-down($mobile-breakpoint) {
    left: 1rem;
    top: 1rem;
    transform: none;
  }

  &.open {
    background-image: url('../images/bg-toggler-icon-open.svg');
  }

  header.smaller & {
    @include media-breakpoint-up(lg) {
      top: calc((#{$height-header-smaller} - #{$height-language} + 10px) / 2);
    }
  }
}

.sidebar-wrapper {
  position: absolute;
  top: $height-header;
  left: 0;
  right: 0;
  height: calc(100vh - #{$height-header});
  background-color: rgba(255, 255, 255, .9);
  z-index: 11;
  padding: 100px 15px;
  font-size: 27px;
  color: $blue;
  overflow: auto;

  body.smaller-header & {
    top: $height-header-smaller;
    height: calc(100vh - #{$height-header-smaller});
  }

  body.admin-menu & {
    @include media-breakpoint-up(lg) {
      height: calc(100vh - #{$height-header} - #{$height-admin-menu});
    }
    @include media-breakpoint-down(md) {
      height: calc(100vh - #{$height-header});
    }
    @include media-breakpoint-down(sm) {
      height: calc(100vh - #{$height-header-sm});
    }
  }

  body.admin-menu.smaller-header & {
    top: $height-header-smaller;
    @include media-breakpoint-up(md) {
      height: calc(100vh - #{$height-header-smaller} - #{$height-admin-menu});
    }
    @include media-breakpoint-down(md) {
      height: calc(100vh - #{$height-header-smaller});
    }
  }
  @include media-breakpoint-down(sm) {
    top: $height-header-sm!important;
    height: calc(100vh - #{$height-header-sm});
    padding: 50px 15px;
  }

  ul.menu {
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      text-align: center;

      & + li {
        margin-top: 30px;
        @include media-breakpoint-down(sm) {
          margin-top: 14px;
        }
      }

      a {
        text-decoration: none;

        &.active {
          // color: inherit;
          // text-decoration: underline;
        }
      }
    }
  }

  ul.language-switcher-locale-url {
    padding: 0;
    margin: 0;
    list-style: none;
    text-transform: uppercase;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 50px;
      background-color: $blue;
      margin: 38px auto;
    }

    li {
      display: inline-block;

      & + li {
        margin-left: 0.5rem;
      }
    }
  }

  .logo {
    width: 62px;
    height: 87px;
    background-image: url('../images/logo-bleu.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 4rem auto 0;
  }
}
