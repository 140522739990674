ul.tabs.primary {
  padding: 0;
  margin: 0 0 0.5rem;
  overflow-x: auto;
  max-width: 100%;

  li {
    display: inline-block;

    & + li {
      margin-left: 0.5rem;
    }

    a {
      margin: 0;
      padding: 0.25rem 1em;
      display: inline-block;
      border-bottom: 0;
    }
  }
}
