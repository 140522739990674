[type="reset"],
[type="submit"],
button,
html [type="button"] {
  @extend .btn;
}

.form-submit {
  @extend .btn-primary;
}

.btn-primary.focus,
.btn-primary:focus,
.focus.form-submit,
.form-submit:focus {
  box-shadow: none;
}
