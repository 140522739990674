button,
input,
optgroup,
select,
textarea {
  outline: none;
}

input:not([type="submit"]):not([type="checkbox"]),
optgroup,
select,
textarea {
  @extend .form-control;
}

label {
  display: block;
}

.grippie {
  display: none;
}

.form-item {
  .description {
    @extend .text-muted;
    margin-top: 0.25rem;
  }
}

.form-type-checkbox,
.form-type-radio {
  @extend .form-check;

  label {
    @extend .form-check-label;
  }

  input {
    @extend .form-check-input;
    margin-left: 0;
  }

  .description {
    margin-left: 1.5em;
  }
}

.form-managed-file {
  input[type="file"] {
    display: inline-block;
    width: auto;
    margin-right: 1rem;
  }
}

.form-submit + .form-submit {
  margin-left: 1rem;
}

.form-submit[id*="delete"] {
  @extend .btn-danger;
}

.form-item-date-date + .form-item-date-time {
  margin-left: 1rem;
}

html.js fieldset.collapsed {
  height: auto;
}

fieldset {
  position: relative;
  margin: 1rem -15px;
  border: solid #f7f7f9;
  padding: 1rem;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  border-width: 0.2rem;

  legend {
    display: inline-block;
    width: auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}
