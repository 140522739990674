body.node-type-quiz {
  form.answering-form {
    @extend .clearfix;
    margin-top: 2rem;

    input[type="submit"] {
      color: $blue;
      background-color: transparent;
      padding: 0;
      border: 0;
      font-size: 20px;
      line-height: $line-height-base;
      padding-right: 20px;
      background-image: url('../images/bg-next.svg');
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 11px 17px;
      float: right;
    }
  }
}
