.view-videos {
  .view-content {
    @extend .row;
  }

  .views-row {
    @extend .col-md-4;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .views-field-edit-node {
    order: 10;
    text-align: right;
    color: #0064bf;
    background-color: transparent;
    padding: 0;
    border: 0;
    font-size: 20px;
    line-height: 1.5;
    padding-right: 20px;
    background-image: url('../images/bg-next.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 11px 17px;
    margin-top: 0.5rem;
  }

  .views-field-title {
    font-weight: 700;
  }
}
